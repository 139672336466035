import { AuthenticationResult, AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from 'react';
import * as AuthInterfaces from '../Interfaces/AuthInterfaces';

export function useGetCurrentLoggedInUser(): AccountInfo {
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();
    const [loggedInUser, setLoggedInUser] = useState<AccountInfo | undefined>(currentAccount || undefined);

    useEffect(() => {
        if (!loggedInUser) {
            const account = instance.getActiveAccount();
            if (account) {
                setLoggedInUser(account);
            }
        }
    }, [loggedInUser, instance]);

    if (loggedInUser) {
        return loggedInUser;
    } else {
        throw new Error("No account is currently logged in");
    }
}

export function useGetUserBySameAccountName(accountIdentifier: string): AccountInfo | undefined {
    const { instance } = useMsal();
    const account = instance.getAccountByUsername(accountIdentifier);
    const [user, setUser] = useState<AccountInfo | undefined>(account || undefined);

    useEffect(() => {
        if (!user && accountIdentifier) {
            const accounts = instance.getAllAccounts();
            const matchedAccount = accounts.find((acc) => acc.username === accountIdentifier);
            if (matchedAccount) {
                setUser(matchedAccount);
            }
        }
    }, [user, instance, accountIdentifier]);

    if (user) {
        return user;
    } else {
        console.log("No account is found for the given SAM account name");
        return undefined;
    }
}

export function useGetAllLoggedInUsers(): AccountInfo[] {
    const { instance } = useMsal();
    const [loggedInUsers, setLoggedInUsers] = useState<AccountInfo[]>([]);

    useEffect(() => {
        if (loggedInUsers.length === 0) {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                setLoggedInUsers(accounts);
            }
        }
    }, [loggedInUsers, instance]);

    if (loggedInUsers.length > 0) {
        return loggedInUsers;
    } else {
        return [];
    }
}

export function useGetAccessToken(scopes: string[]): AuthenticationResult | undefined {
    const { instance } = useMsal();
    const [tokenResponse, setTokenResponse] = useState<AuthenticationResult | undefined | void>(undefined);

    useEffect(() => {
        if (!tokenResponse && scopes && scopes.length > 0) {
            const request = {
                scopes: scopes,
            };

            const acquireToken = async () => {
                try {
                    await instance.initialize();
                    const response = await instance.acquireTokenSilent(request);
                    setTokenResponse(response);
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        try {
                            const response = await instance.acquireTokenPopup(request);
                            setTokenResponse(response);
                        } catch (err) {
                            if (err instanceof InteractionRequiredAuthError) {
                                instance.acquireTokenRedirect(request);
                            } else {
                                throw err;
                            }
                        }
                    } else {
                        throw error;
                    }
                }
            };

            acquireToken();
        }
    }, [tokenResponse, instance, scopes]);

    if (tokenResponse) {
        return tokenResponse;
    } else {
        console.log("Failed to acquire access token");
        return undefined;
    }
}

export function useGetAccessTokenString(scopes: string[]): string | undefined {
    const tokenResponse = useGetAccessToken(scopes);
    if (tokenResponse?.accessToken && scopes && scopes.length > 0) {
        return tokenResponse.accessToken;
    } else {
        return undefined;
    }
}

export function useGetAccessTokenResponse(scopes: string[]): AuthInterfaces.TokenParts | undefined {
    const tokenResponse = useGetAccessTokenString(scopes);
    if (tokenResponse && scopes && scopes.length > 0) {
        const token_parts = tokenResponse.split(".");
        if (token_parts && token_parts.length === 3) {
            const token_header = JSON.parse(atob(token_parts[0]));
            const token_payload = JSON.parse(atob(token_parts[1]));
            return {
                header: token_header,
                payload: token_payload,
            };
        } else {
            throw new Error("Invalid token");
        }
    } else {
        return undefined;
    }
}

export function useLoginByRedirect(scopes: string[]): void {
    const { instance } = useMsal();
    const request = {
        scopes: scopes,
    };
    useEffect(() => {
        const login = async () => {
            await instance.initialize();
            instance.loginRedirect(request);
        };

        login();
    }, [instance, request]);
}

export function useLoginByPopup(scopes: string[]): AuthenticationResult {
    const { instance } = useMsal();
    const request = {
        scopes: scopes,
    };
    const [loginResult, setLoginResult] = useState<AuthenticationResult | undefined>(undefined);

    useEffect(() => {
        const login = async () => {
            try {
                const result = await instance.loginPopup(request);
                setLoginResult(result);
            } catch (error) {
                console.error("Failed to login by popup", error);
                throw error;
            }
        };

        login();
    }, [instance]);

    if (loginResult) {
        return loginResult;
    } else {
        throw new Error("Failed to login by popup");
    }
}

export function useLogout(): void {
    const { instance } = useMsal();
    const request = {};
    useEffect(() => {
        const logout = async () => {
            await instance.initialize();
            instance.logout(request);
        };

        logout();
    }, [instance]);
}

export function useLogoutByRedirect(): void {
    const { instance } = useMsal();
    const request = {};
    useEffect(() => {
        const logout = async () => {
            await instance.initialize();
            instance.logoutRedirect(request);
        };

        logout();
    }, [instance, request]);
}

export function useLogoutByPopup(): void {
    const { instance } = useMsal();
    const request = {};
    useEffect(() => {
        const logout = async () => {
            await instance.initialize();
            instance.logoutPopup(request);
        };

        logout();
    }, [instance, request]);
}

export function useIsInRoles(roles: string[], samAccountName?: string): boolean {
    const [isInRoles, setIsInRoles] = useState<boolean>(false);
    const { instance } = useMsal();
    useEffect(() => {
        const checkRoles = async () => {
            let userRoles: string[] = [];
            if(roles && roles.length > 0) {
                const account = samAccountName ? instance.getAccountByUsername(samAccountName) : instance.getActiveAccount();
                userRoles = [...(account?.idTokenClaims?.roles || [])];
                setIsInRoles(roles.some((role) => userRoles?.includes(role)) || false);
            }
        };

        checkRoles();
    }, [roles, samAccountName]);
    return isInRoles;
}

export function useIsInRolesInAccessToken(roles: string[], scopes: string[]): boolean {
    const [isInRoles, setIsInRoles] = useState<boolean>(false);
    const accessTokenResponse = useGetAccessTokenResponse(scopes);
    useEffect(() => {
        const checkRoles = async () => {
            let userRoles: string[] = [];
            if(roles && roles.length > 0) {
                if (scopes && scopes.length > 0 && accessTokenResponse) {
                    userRoles = [...(accessTokenResponse?.payload?.roles || [])];
                }
                setIsInRoles(roles.some((role) => userRoles?.includes(role)) || false);
            }
        };

        checkRoles();
    }, [roles, scopes, accessTokenResponse]);

    return isInRoles;
}