import { Input } from './Input';

export type InputWithErrorProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string | null;
  highlightError?: boolean;
};

export const InputWithError = ({
  ...elementProps
}: InputWithErrorProps) => {
  return (
      <Input
        {...elementProps}
        
      />
  );
};
