import React, { ChangeEvent } from 'react';
import './paginationComponentStyle.scss';

interface PaginationProps {
    page: number;
    rowsPerPage: number;
    totalRows: number;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    page,
    rowsPerPage,
    totalRows,
    onPageChange,
    onRowsPerPageChange
}) => {
    return (
        <div className="pagination-container">
            <select
                className="pagination-select"
                value={rowsPerPage}
                onChange={onRowsPerPageChange}
            >
                {[5, 10, 25].map(rows => (
                    <option key={rows} value={rows}>
                        {`${rows} rows`}
                    </option>
                ))}
            </select>
            <button
                className="pagination-button"
                onClick={() => onPageChange(page - 1)}
                disabled={page === 0}
            >
                Previous
            </button>
            <button
                className="pagination-button"
                onClick={() => onPageChange(page + 1)}
                disabled={(page + 1) * rowsPerPage >= totalRows}
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;
