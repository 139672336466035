import Headers from "../headers/header";
import Footer from "../footer/footer";
import TableComponent from "../tableComponent/tableComponent";
import NpiSearchForm from "../npiSearchForm/npiSearchForm";
import {
  useGetCurrentLoggedInUser,
  useGetAccessTokenString,
} from "../../auth-modules/hooks/authHooks";
import { setAuth } from "../../slice/authSlice";
import { useAppDispatch } from "../../hooks";

const scopes = `${process.env.REACT_APP_AZUREAD_SCOPES}`.split(",") || [];

const HomePage = () => {
  // Authoriztion & Authentication MSal Service
  const accountInfo = useGetCurrentLoggedInUser();
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessTokenString(scopes);

  if (accountInfo !== undefined) {
    dispatch(
      setAuth({ name: accountInfo.name as string, token: `${accessToken}` })
    );
  }
  return (
    <div data-testid="app">
      <Headers />
      <NpiSearchForm />
      <TableComponent />
      <Footer />
    </div>
  );
};

export default HomePage;
