import React from 'react';
import { InputWithError } from '../control/form-controls/InputWithError';
import './npiSearchFormStyle.scss';
interface InputFieldProps {
    id: string;
    name: string;
    label: string;
    error: string | null;
    formValue: string;
    onChange: (event: any) => void;
}

const InputField: React.FC<InputFieldProps> = ({ id, name, label, error, formValue, onChange }) => {
    return (
        <div className="input-group">
            {label && <label htmlFor={name ?? ''} >{label}</label>}
            <InputWithError id={id} name={name} errorMessage={error}
                className={error ? 'error' : ''} onChange={onChange} />
            {error && <span className="error-message">{error}</span>}
        </div>
    );
};


export default InputField;