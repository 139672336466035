import React from 'react'
import './footerStyle.scss'
const Footer = () => {
  return (
    <div className='footer'>
      <p>Sentara NPI Search</p>
    </div>
  )
}

export default Footer
