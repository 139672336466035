export const CheckValidation = (name: string, value: string) => {
    const onlynumericPattern = /^[0-9]+$/;
    const taxIdPattern = /^\d{2}-\d{7}$/;
    const alphanumericPattern = /^[0-9a-zA-Z\s]+$/; // Updated to include spaces
    const consecutiveSpacesPattern = /\s{2,}/; // Pattern to check for consecutive spaces

    switch (name) {
        case 'lastName':
            if (!alphanumericPattern.test(value) && value !== '') {
                return 'You have entered invalid characters';
            } 
            else if (consecutiveSpacesPattern.test(value)) {
                return 'Last Name should not contain consecutive spaces';
            } 
            else if (value.length > 50) {
                return 'lastname should not be greater than 50 characters';
            }
            break;
        case 'firstName':
            if (!alphanumericPattern.test(value) && value !== '') {
                return 'You have entered invalid characters';
            }
            else if (consecutiveSpacesPattern.test(value)) {
                return 'First Name should not contain consecutive spaces';
            } 
            else if (value.length > 50) {
                return 'firstname should not be greater than 50 characters';
            }

            break;
        case 'upin':
            if (!alphanumericPattern.test(value) && value !== '') {
                return 'You have entered invalid characters';
            } else if (value.length > 10) {
                return 'upin should not be greater than 10 characters';
            }
            break;
        case 'npi':
            if (!onlynumericPattern.test(value)  && value !== '') {
                return 'You have entered invalid character. Numbers only';
            } else if (value.toString().length > 10) {
                return 'Npi should not be greater than 10 characters';
            }
            break;
        case 'taxId':
            if (!taxIdPattern.test(value) && value !== '') {
                return 'You have entered invalid character.(xx-xxxxxxx)';
            }
            break;
        default:
            return null;
    }
}
