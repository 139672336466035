import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { INpiSearch } from '../types/INpiSearch';
import { IResponse } from '../types/IResponse';
import { prepHeaders } from './prepHeaders';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}`;
const tag = 'npiSearchApi';

export const npiSearchApi = createApi({
    reducerPath: 'npiSearch',
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepHeaders }),
    tagTypes: [tag],
    endpoints: (builder) => ({
        create: builder.mutation<IResponse, INpiSearch>({
            query: (formData : INpiSearch) => {
                const url = `api/v1/npiservices/searchrequest`;
                return { url, method: 'POST', body: formData };
            },
            invalidatesTags: [tag],
        }),
    }),
});


export const { useCreateMutation } = npiSearchApi;
