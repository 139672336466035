import React from "react";
/**
 * `npiSearchLoader.tsx` is a React component that renders a loader while the error lookup is in progress.
 *
 * The component receives a `loading` prop, which is a boolean indicating whether the npi search is currently loading.
 *
 * If `loading` is true, the `npiSearchLoader` component renders a loader (e.g., a spinner or a progress bar). If `loading` is false, it renders its children.
 *
 * The `npiSearchLoader` component is exported so it can be imported and used in other parts of the application.
 */

import './npiSearchLoaderStyle.scss';
const NpiSearchLoader = () => {
  return (
    <div className="loader-container" data-testid="spinner" >
      <svg className="spinner" viewBox="0 0 50 50">
        <img src="./icons8-spinner.gif" alt="loader" />
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    </div>
  );
};

export default NpiSearchLoader;