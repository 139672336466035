import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";


import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { store } from "./store";
import MsalService from "./auth-modules/msalService";
import App from "./components/app/app";
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  
  const msalService = new MsalService(); // Create an instance of the MsalService class
  
  root.render(
      <Provider store={store}>
        <ToastContainer position="top-right" />
        <BrowserRouter>
          <App instance={msalService.getInstance()} />
        </BrowserRouter>
      </Provider>
  );
}
