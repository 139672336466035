import React, { useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AuthenticationResult, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import * as AuthInterfaces from '../Interfaces/AuthInterfaces';
import { isInRoles } from '../utils/tokenUtils';

interface ErrorComponentProps {
    error: any;
}

/**
 * Error component to display an error message.
 *
 * @component
 * @param {ErrorComponentProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => (
    <p>An Error Occurred: {error}</p>
);

/**
 * Loading component to display a loading message during authentication.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const LoadingComponent: React.FC = () => <p>Authentication in progress...</p>;

/**
 * Higher-order component that provides authentication and authorization functionality.
 * Renders the protected component if the user is authorized, otherwise render component as per route type or displays an error message.
 *
 * @component
 * @param {IAppRoute} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export const AuthGuard: React.FC<PropsWithChildren<AuthInterfaces.IAppRoute>> = (props:any) => {
    const { instance } = useMsal();
    const location = useLocation();
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
     
    const acquireToken = useCallback(async (scopes: string[]):Promise<AuthenticationResult | undefined> => {
        let authResult:AuthenticationResult | undefined = undefined;
        const request = {
            scopes: scopes,
        };
        
        try {
            await instance.initialize();
            authResult = await instance.acquireTokenSilent(request);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    authResult =  await instance.acquireTokenPopup(request);
                } catch (err) {
                    if (err instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenRedirect(request);
                    } else {
                        setIsAuthorized(false);
                        console.error("Error while fetching accessToken", err);
                        throw error;
                    }
                }
            } else {
                setIsAuthorized(false);
                console.error("Failed to accquire accessToken", error);
                return undefined;
            }
        }
        console.dir('AuthResult : ', authResult);
        return authResult ?? undefined;
    }, [instance, setIsAuthorized, props.accessRequest?.authRequest?.scopes])


    /**
     * Checks if the user is authorized based on the provided roles and scopes.
     * Sets the `isAuthorized` state accordingly.
     *
     * @async
     * @returns {Promise<void>} A promise that resolves when the authorization check is complete.
     */
    const checkIsAuthorized = useCallback(async (useAccessToken: boolean = false): Promise<void> => {

        const checkAccessTokenAuthorization = async (): Promise<boolean> => {
            
            if (props.accessRequest?.authRequest?.scopes && props.accessRequest?.authRequest?.scopes.length > 0) {
                const tokenResponse = await acquireToken(props.accessRequest?.authRequest?.scopes as string[]);
                if(tokenResponse && tokenResponse?.accessToken?.length > 0) {
                    return await isInRoles(props.accessRequest?.roles as string[], tokenResponse?.accessToken);
                }
            }
            return false;
        };

        const checkIdTokenAuthorization = async (): Promise<boolean> =>  {
            await instance.initialize();
            
            const currentUser = instance.getActiveAccount();
             if(currentUser?.idTokenClaims && currentUser.idTokenClaims.roles && currentUser.idTokenClaims.roles.length > 0) {
                return await isInRoles(props.accessRequest?.roles as string[], currentUser?.idToken as string);
             }
             return false;
        };

        if (props.routeType === AuthInterfaces.AppRouteType.Protected) {
            let userIsAuthorized = false;
            
            if (useAccessToken) {
                userIsAuthorized = await checkAccessTokenAuthorization();
            } else {
                userIsAuthorized = await checkIdTokenAuthorization();
            }
            setIsAuthorized(userIsAuthorized);
        }
    }, [setIsAuthorized, props.accessRequest?.roles, props.accessRequest?.authRequest?.scopes, props.routeType]);

    useEffect(() => {
        checkIsAuthorized(props?.accessRequest?.authorizeWithAceesToken || false);
    }, [checkIsAuthorized]);

    const Component = props.element;
    // Render based on route type
    switch (props.routeType) {
        case AuthInterfaces.AppRouteType.Public:
            return props.children || <Component />;
        case AuthInterfaces.AppRouteType.Unauthenticated:
            return <UnauthenticatedTemplate>{props.children || <Component props={props} />}</UnauthenticatedTemplate>;
        case AuthInterfaces.AppRouteType.Authenticated:
            return (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={props.accessRequest?.authRequest}
                    errorComponent={props.errorComponent || ErrorComponent}
                    loadingComponent={props.loadingComponent || LoadingComponent}
                >                   
                    {props.children || <Component />}
                </MsalAuthenticationTemplate>
            );
        case AuthInterfaces.AppRouteType.Protected:
            return (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={props.accessRequest?.authRequest}
                    errorComponent={props.errorComponent || ErrorComponent}
                    loadingComponent={props.loadingComponent || LoadingComponent}
                >
                    { isAuthorized ? (
                        props.children || <Component />
                    ) : (
                        <div className="data-area-div">
                            <h3>
                                You are unauthorized to view this content. Please assign yourself to the
                                {location.pathname === props.path ? (
                                     <span>
                                         {' '}
                                         Roles : {props.accessRequest?.roles?.join(', ')} or Scopes : {props.accessRequest?.authRequest?.scopes?.join(', ')}
                                     </span>
                                ) : (
                                    <span> required Roles or try to relogin.. </span>
                                )}
                            </h3>
                        </div>
                    )}
                </MsalAuthenticationTemplate>
            );
        default:
            return <h3>Error Occured while performing authentication/authorization</h3>;
    }
};

