/**
 * `appInsight.ts` is a file that sets up and configures Application Insights for the application.
 *
 * Application Insights is a service that allows developers to monitor performance, availability, and usage of their applications.
 *
 * This file exports a function that initializes Application Insights with a given instrumentation key.
 * It also exports a function to log custom events or metrics to Application Insights.
 *
 * The exported functions are used throughout the application to monitor performance, track errors, and log custom events or metrics.
 */

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

reactPlugin = new ReactPlugin();

appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
  },
});
const telemtryInitializer = (envelope: ITelemetryItem) => {
  if (envelope.baseType === process.env.REACT_APP_LOGGING_LEVEL_TELEMETRY) {
    return false;
  }
  return true;
};
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemtryInitializer);

export { reactPlugin, appInsights };
