import { Route, Routes } from "react-router-dom";
import {
  IAppRoute,
  AppRouteType,
} from "./auth-modules/Interfaces/AuthInterfaces";
import { AuthGuard } from "./auth-modules/components/AuthGuard";
import HomePage from "./components/home/homePage";

export const routes: IAppRoute[] = [
  {
    name: "Home",
    path: "/",
    element: (route: IAppRoute) => (
        <HomePage />
    ),
    routeType: AppRouteType.Authenticated,
    //  TODO: Add accessRequest with npi search role for authorization
  //   accessRequest: {
  //     authRequest: {
  //       scopes: process.env.REACT_APP_AZUREAD_SCOPES?.split(",") || [],
  //     },
  //     roles: process.env.REACT_APP_AZUREAD_ROLES?.split(",") || [],
  //     authorizeWithAceesToken: false,
  //   },
  //   routeType: AppRouteType.Protected, 
  },
];

const AppRoutes = () => {

  return (
    <Routes>
      {routes.map((route: IAppRoute) => (
        <Route
          key={route.name}
          path={route.path}
          element={<AuthGuard {...route} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
