import { useEffect, useState} from 'react';
import { useCreateMutation } from '../services/npiSearchAPI';
import { getErrorMessage } from '../components/common/errorMessage';

const useFetchNpiSearch = ()  => {
    const [
        create,
        {
            isSuccess,
            data,
            error,
            isLoading
        }
    ] = useCreateMutation();

    const [myError , setMyError] = useState("");

    useEffect(() => {
        if (error) {
            const errMsg = getErrorMessage(error as Error);
            setMyError(errMsg);
        } else {
            // Clear error message when there is no error
            setMyError("");
        }
    }, [error]);

    useEffect(() => {
        if (isSuccess) {
            // Clear error message when data is successfully fetched
            setMyError("");
        }
    }, [isSuccess, data]);

    return { myError, create, isLoading };
};

export default useFetchNpiSearch;
