// FILE: npiSearchDataSlice.tsx
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface NpiSearchDataState {
  responseData: any; 
}

const initialState: NpiSearchDataState = {
  responseData: null,
};

export const npiSearchDataSlice = createSlice({
  name: 'npiSearchData',
  initialState,
  reducers: {
    setResponseData: (state, action: PayloadAction<any>) => { 
      state.responseData = action.payload;
    },
    clearResponseData: (state) => {
      state.responseData = initialState.responseData;
    },
  },
});

export const selectNpiSearchData = (state: RootState) => state.npiSearchData;

export const { setResponseData, clearResponseData } = npiSearchDataSlice.actions;

export default npiSearchDataSlice.reducer;