import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import './tableComponentStyle.scss';
import { useSelector } from 'react-redux';
import { selectNpiSearchData } from '../../slice/npiSearchDataSlice';
import Pagination from '../pagination/paginationComponent';

interface Data {
    lastName: string;
    firstName: string;
    upin: string;
    taxId: string;
    npi: null;
}

interface Column {
    id: keyof Data;
    label: string;
    minWidth: number;
    align: 'left' | 'right';
}

const columns: Column[] = [
    { id: 'lastName', label: 'Last Name', minWidth: 100, align: 'left' },
    { id: 'firstName', label: 'First Name', minWidth: 100, align: 'left' },
    { id: 'upin', label: 'UPIN', minWidth: 100, align: 'left' },
    { id: 'taxId', label: 'Tax ID', minWidth: 100, align: 'left' },
    { id: 'npi', label: 'NPI', minWidth: 10, align: 'left' }
];

const TableComponent = () => {
    const { responseData } = useSelector(selectNpiSearchData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const tableData = useMemo(() => {
        if (responseData?.data) {
            return responseData.data;
        }
        return [];
    }, [responseData]);

    useEffect(() => {
        setPage(0);
    }, [responseData]);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!responseData?.data) {
        return null;
    }

    return (
        <div className='main-component'>
            <div className='table-container'>
                <table className='sticky-table'>
                    <thead className='table-header'>
                        <tr>
                            {columns.map(column => (
                                <th key={column.id} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(rowsPerPage > 0
                            ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : tableData
                        ).map((row: Data, index: number) => (
                            <tr key={index}>
                                {columns.map(column => (
                                    <td key={column.id} className='table-data'>
                                        {row[column.id]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalRows={tableData.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default TableComponent;
