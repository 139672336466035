export function getErrorMessage(error: any): string {
  switch (true) {
      case error.data && error.status === 404:
          return error.data;
      case error.status === 500:
          return error.data.appErrorMsg;
      default:
          return error.error;
  }
}
