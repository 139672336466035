import { PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import React, { PropsWithChildren } from 'react';

/**
 * Represents an application route.
 */
export interface IAppRoute {
    /**
     * The name of the route.
     */
    name: string;
    /**
     * The URL path of the route.
     */
    path: string;
    /**
     * The accessRequest required is authentication/authorization for the route.
     */
    accessRequest?: AccessRequest;
    /**
     * The loading component to be displayed while the route is loading.
     */
    loadingComponent?: React.FC<any>;
    /**
     * The error component to be displayed if there is an error loading the route.
     */
    errorComponent?: React.FC<any>;
    /**
     * The component to be rendered for the route.
     */
    element: React.FC<PropsWithChildren<any>>;
    /**
     * Indicates whether the route is protected and requires authentication.
     */
    routeType: AppRouteType;
}

/**
 * Represents the type of an application route.
 */
export enum AppRouteType {
    /**
     * Represents a public route that is accessible to all users.
     */
    Public,

    /**
     * Represents a protected route that requires authentication/authorization to access.
     */
    Protected,

    /**
     * Represents an authenticated route that requires the user to be logged in.
     */
    Authenticated,

    /**
     * Represents an unauthenticated route that requires the user to be logged out.
     */
    Unauthenticated
}

/**
 * Represents the request object for authentication.
 */
export type AccessRequest = {
    authRequest: PopupRequest | RedirectRequest | SsoSilentRequest | undefined; 
    /**
     * The roles required for authentication.
     */
    roles: string[];
    authorizeWithAceesToken?: boolean;
}

export interface TokenParts {
    header: any;
    payload: any;
}

export interface RequestBody {
    url: URL | RequestInfo;
    params?: URLSearchParams[];
    config: RequestInit;
    authorizationToken?:string
}