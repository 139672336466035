/**
 * `store.tsx` is the file where the Redux store is configured and created.
 *
 * The Redux store is a predictable state container for JavaScript apps.
 * It serves as the single source of truth for the state of your app, with rules ensuring that the state can only be updated in a predictable fashion.
 *
 * This file imports the `configureStore` function from Redux Toolkit, which simplifies the setup process.
 * It also imports the `authReducer` and other reducers from the slices.
 *
 * The `store` constant is created by calling `configureStore` and passing in an object with the following properties:
 * - `reducer`: An object that maps the names of different slices of the state to their corresponding reducers.
 * - `middleware`: An array of Redux middleware to install. If not supplied, Redux Toolkit will add some middleware by default.
 *
 * The `store` is then exported for use in the `Provider` component in your app, which makes the Redux store available to any nested components that have been wrapped in the `connect()` function.
 *
 * The `RootState` type, which represents the entire state of the Redux store, is also exported. This is useful for type checking in other parts of the app.
 */

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './slice/authSlice'
import { npiSearchApi } from './services/npiSearchAPI'
import npiSearchData from './slice/npiSearchDataSlice'
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    auth: authReducer,
    npiSearchData: npiSearchData,
    [npiSearchApi.reducerPath]: npiSearchApi.reducer,
   
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(npiSearchApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


