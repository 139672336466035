import React, { useEffect, useState } from 'react';
import './npiSearchFormStyle.scss';
import { INpiSearch } from '../../types/INpiSearch';
import InputField from './InputField';
import { CheckValidation } from './checkValidation';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../utils/appInsights/configs/appInsight';
import useFetchNpiSearch from '../../hooks/useFetchNpiSearch';
import { useDispatch } from 'react-redux';
import { clearResponseData, setResponseData } from '../../slice/npiSearchDataSlice';
import NpiSearchLoader from '../spinner/npiSearchLoader';

const NpiSearchForm = () => {

    const [searchTriggered, setSearchTriggered] = useState(false);
    // Form state
    const [form, setForm] = useState<INpiSearch>({
        lastName: '',
        firstName: '',
        upin: '',
        taxId: '',
        npi: null
    });

    // Error state
    const [errors, setErrors] = useState({
        lastName: null,
        firstName: null,
        upin: null,
        taxId: null,
        npi: null
    });
    const dispatch = useDispatch();

    // Form validation
    const [isValid, setIsValid] = useState<boolean>(false);

    // Get the fetchData function from the useFetchNpiSearch hook
    const { create, myError, isLoading } = useFetchNpiSearch();

    // Check if form is valid
    useEffect(() => {
        const checkFormIsValid = () => {
            const formValues = Object.values(form);
            const hasValues = formValues.some(value => value !== '');
            const hasErrors = Object.values(errors).some(error => error !== null && error !== undefined);
            setIsValid(hasValues && !hasErrors);
        }
    
        checkFormIsValid();
    }, [form, errors]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
    
        let error = CheckValidation(name, value);
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    
        if (name === 'npi') {
            setForm(prevForm => ({ ...prevForm, [name]: value ? Number(value) : null }));
        } else {
            setForm(prevForm => ({ ...prevForm, [name]: value }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Clear the previous state
    dispatch(clearResponseData());
    // Call the mutation function with the form data
    try {
        const response = await create(form);

        // Dispatch the response data to the store
        dispatch(setResponseData(response));
        // Update the state variable to trigger a re-render
        setSearchTriggered(!searchTriggered);
    } catch (error) {
        return 'Failed to submit form';
    }
}

    return (
        <div className='form-container'>
            <form onSubmit={handleSubmit}>
                <InputField id="lastName" name="lastName" label='Last Name' error={errors.lastName} formValue={form.lastName} onChange={handleChange} />
                <InputField id="firstName" name="firstName" label='First Name' error={errors.firstName} formValue={form.firstName} onChange={handleChange} />
                <InputField id="upin" name="upin" label='UPIN' error={errors.upin} formValue={form.upin} onChange={handleChange} />
                <InputField id="taxId" name="taxId" label='TaxID' error={errors.taxId} formValue={form.taxId} onChange={handleChange} />
                <InputField id="npi" name="npi" label='NPI' error={errors.npi} formValue={form.npi ? form.npi.toString() : ''} onChange={handleChange} />
                <div className="button">
                    <button type="submit" disabled={!isValid || isLoading}>Search</button>
                    {isLoading && <NpiSearchLoader />}
                </div>
                {myError && <p className="error-message">{myError}</p>}
            </form>
        </div>
    );

}
export default withAITracking(reactPlugin, NpiSearchForm, 'npiSearchForm');