/**
 * Header component that displays the title of the application.
 * It is a functional component that returns a JSX element.
 */
import './headerStyle.scss';
const Header = () => {
    return (
    <div className="header">
      <p>NPI Search</p>
    </div>
  );
}
export default Header;
