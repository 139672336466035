/**
 * `prepHeaders` is a utility function that prepares the headers for API requests.
 *
 * It returns an object containing the headers required for API requests.
 * These headers typically include content type, authorization tokens, etc.
 *
 * This function is used in the `baseQuery` of the `errorsApi` service to prepare the headers for each API request.
 *
 * The headers returned by this function can be customized based on the requirements of the API.
 */

import { isExpired } from "../auth-modules/utils/tokenUtils";
import { toast } from "react-toastify";

export const prepHeaders = async (headers: Headers, { getState } : any) => {
    const token = getState()?.auth?.token;
    const ocpApimSubscriptionKey = process.env.REACT_APP_AZUREAD_OCPAPIMSUBSCRIPTIONKEY ?? "";
    const exp = await isExpired(token);
    if(exp) {
        toast.error("Session has expired.. please refresh the page.");
        window.location.reload();
    }
    
    headers.set("Authorization", `Bearer ${token || ''}`);
    headers.set("Ocp-Apim-Subscription-Key", ocpApimSubscriptionKey);
    return headers;
};