export const Input = ({
    ...elementProps
  }: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
    return (
        <input data-testid="input"
          autoComplete="off"
          {...elementProps}
          className={` ${elementProps.className}`}
        />
    );
  };
  